<template>
    <div class="activity-schedule">
        <div class="return_page">
            <a @click="goBack">
                <i class="fas fa-arrow-left"></i>
                <span class="mobile">
                    {{ $t("vigik.navigation.list-provider") }}
                </span>
            </a>
        </div>
        <div class="top-block">
            <h1 class="title">
                {{
                    $t("vigik.schedule.title", {
                        name: $route.params.providerName,
                    })
                }}
            </h1>
        </div>

        <week-calendar
            v-if="display"
            :readonly="false"
            :days="days"
            :maxTimeRange="10"
            :maxDifferentDay="7"
            :initialValue="initialValue"
            @change="updateValue"
        />
        <div class="center" v-else>
            <Loader />
        </div>
    </div>
</template>

<script>
import WeekCalendar from "@/components/basic/WeekCalendarLite.vue"
import BasicButton from "@/components/basic/BasicButton.vue"
import {
    getProviderSchedule,
    saveProviderSchedule,
} from "@/services/intratone/accesstype"
import Loader from "@/components/basic/Loader"
export default {
    name: "ActivitySchedule",
    components: {
        Loader,
        WeekCalendar,
    },
    data() {
        return {
            days: [
                { id: 0, name: "monday" },
                { id: 1, name: "tuesday" },
                { id: 2, name: "wednesday" },
                { id: 3, name: "thursday" },
                { id: 4, name: "friday" },
                { id: 5, name: "saturday" },
                { id: 6, name: "sunday" },
            ],
            initialValue: [],
            display: false,
            displayArray: [],
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        getData() {
            this.display = false
            getProviderSchedule(
                this.$route.params.accessId,
                this.$route.params.providerId,
                { origin: this.$route.params.originId }
            ).then((response) => {
                this.initialValue = response.data.days
                this.display = true
            })
        },
        goBack() {
            window.history.back()
        },
        updateValue(payload) {
            this.displayArray = payload.periods
        },
        saveVerif() {
            this.openModal("validation", {
                title: "valid.week-type",
                question: this.$t("vigik.schedule.edit-schedule"),
                valid: () => {
                    this.save()
                },
            })
        },
        cancelVerif() {
            this.openModal("validation", {
                title: "valid.cancel",
                question: this.$t("vigik.schedule.edit-schedule-cancel"),
                valid: () => {
                    this.getData()
                },
            })
        },
        save() {
            saveProviderSchedule(
                this.$route.params.accessId,
                this.$route.params.providerId,
                {
                    mode: "full",
                    days: this.displayArray,
                }
            )
        },
        razVerif() {
            this.openModal("validation", {
                title: "valid.raz",
                question: this.$t("vigik.custom.raz"),
            })
        },
    },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/_loader.scss";
@import "@/assets/scss/variables/fontSize.scss";

.btn-cancel {
    @media all and (max-width: 768px) {
        display: none;
    }
}

.btn-save {
    @media all and (max-width: 768px) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        align-content: center;
        position: absolute;
        top: 85%;
        left: 85%;
        background-color: $orange-neutral;
        width: 40px;
        height: 40px;
        border-radius: 30px;
        font-size: $big;
        color: $white;
        cursor: pointer;
        z-index: 10;
        .text {
            display: none;
        }
        .icon {
            display: block;
        }
    }
    @media all and (min-width: 768px) {
        .text {
            display: block;
        }
        .icon {
            display: none;
        }
    }
}

.btn-raz {
    @media all and (max-width: 768px) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        align-content: center;
        position: absolute;
        top: 78%;
        left: 85%;
        background-color: $blue-neutral;
        width: 40px;
        height: 40px;
        border-radius: 30px;
        font-size: $big;
        color: $white;
        cursor: pointer;
        z-index: 10;
        .text {
            display: none;
        }
        .icon {
            display: block;
        }
    }
    @media all and (min-width: 768px) {
        .text {
            display: block;
        }
        .icon {
            display: none;
        }
    }
}
@media all and (max-width: 768px) {
    .mobile {
        display: none;
    }
}

.activity-schedule {
    width: 100vw;
    height: 100%;
    padding-top: 10px;
    padding-left: 40px;
    padding-right: 40px;
    overflow: auto;
    .center {
        width: 100%;
        height: 200px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        align-items: center;
    }
    .return_page {
        @media all and (max-width: 768px) {
            margin: 20px;
        }
        @media all and (min-width: 768px) {
            margin: 0 auto;
        }
        height: 4%;
        a {
            color: $blue-darker;
            cursor: pointer;
            text-decoration: none;
            transition: color 200ms;
            font-family: "Avenir Heavy";
            font-size: 12px;
            i {
                margin-right: 10px;
                transition: margin 200ms;
            }
        }
        a:hover {
            color: $orange-neutral;
            i {
                margin-right: 15px;
            }
        }
    }
    .button-block {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-content: center;
        align-items: center;
        flex-wrap: nowrap;
        gap: 20px;
        margin: 20px;
        padding-left: 150px;
        .btn-style {
            padding: 20px;
        }
    }
    .top-block {
        padding-bottom: 6em;
        .title {
            font-family: $font_avenir_black;
            width: 100%;
            @media all and (min-width: 768px) {
                font-size: $veryBig;
            }
            @media all and (max-width: 768px) {
                font-size: $big;
            }
        }
        @media all and (max-width: 768px) {
            padding-bottom: 20px;
            margin-top: -60px;
            padding-left: 40px;
            padding-bottom: 6em;
            .title {
                font-size: $big;
            }
        }
    }
}
</style>
